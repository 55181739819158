import Vue from 'vue'

Vue.component('ExpandedRte', {
    props: {
        useHideInitialText: {
            type: Boolean,
            default: false
        },
    },
    data: () => ({
        isExpanded: false,
        hideInitialText: false
    }),
    methods: {
        toggleContent() {
            if (this.useHideInitialText) {
                this.hideInitialText = !this.hideInitialText
            }
            this.isExpanded = !this.isExpanded
        }
    }
})