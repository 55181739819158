﻿const TypeaheadMixin = () => {
    return {
        props: {
            typeaheadTake: {
                type: Number,
                default: 10
            },
            typeaheadMin: {
                type: Number,
                default: 3
            }
        },
        data: () => ({
            typeaheadResults: [],
            blurred: true,
            focusedIndex: -1
        }),
        mounted() {

        },
        destroyed() {

        },
        computed: {
            showTypeahead() {
                return this.hasTypeaheadResults && !this.blurred
            },
            hasTypeaheadResults() {
                return this.typeaheadResults && this.typeaheadResults.length > 0
            }
        },
        methods: {
            onTypeaheadSuccess(response) {
                let responseData = JSON.parse(response.data)
                this.typeaheadResults = responseData.results
                return Promise.resolve()
            },
            onTypeaheadError(response) {
                console.log(response)
                this.typeaheadResults = []
                return Promise.resolve()
            },    
            focusInput() {
                this.blurred = false
            },
            blurInput() {
                setTimeout(() => {                    
                    this.resetTypeahead()
                    this.blurred = true
                }, 250)
            },
            typeaheadKeydown() {
                if (this.showTypeahead) {
                    if (this.focusedIndex >= (this.typeaheadResults.length - 1)) {
                        this.focusedIndex = 0
                    } else {
                        this.focusedIndex++
                    }                    
                }
            },
            typeaheadKeyup() {
                if (this.showTypeahead) {
                    if (this.focusedIndex <= 0) {
                        this.focusedIndex = (this.typeaheadResults.length - 1)
                    } else {
                        this.focusedIndex--
                    }                    
                }
            },
            redirectToTypeaheadResult() {
                if (this.focusedIndex > -1 && (this.typeaheadResults && this.typeaheadResults.length > this.focusedIndex && this.typeaheadResults[this.focusedIndex])) {
                    window.location.href = this.typeaheadResults[this.focusedIndex].Url              
                    return true
                }   
                return false
            },
            isFocusedIndex(index) {
                return this.focusedIndex === index
            },
            resetTypeahead() {
                this.typeaheadResults = []
                this.focusedIndex = -1
            },
            isValidKeyCode(code) {
                return (code >= 65 && code <= 90) || (code === 8 || code === 46)
            }
        }
    }
}

module.exports = TypeaheadMixin