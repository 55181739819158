import Vue from 'vue'
const TypeaheadMixin = require('../mixins/Typeahead')

Vue.component('SimpleTypeahead', {
    props: {
        initialData: {
            type: String
        }
    },
    mixins: [        
        TypeaheadMixin()
    ],
    data: () => ({        
        allItems: [],        
        keyword: ''
    }),    
    mounted() {
        let initialData = JSON.parse(this.initialData)
        this.allItems = initialData.items
    },
    computed: {
        hasKeyword() {
            return this.keyword && this.keyword.length >= this.typeaheadMin
        }
    },
    watch: {
        keyword: function () {
            this.typeaheadResults = []
            if (this.keyword.length >= this.typeaheadMin) {
                const searchTerm = this.keyword.toLowerCase()
                for (var i = 0; i < this.allItems.length; i++) {
                    let searchTitle = this.allItems[i].Title.toLowerCase()
                    if (searchTitle.includes(searchTerm) || this.hasMatchingSynonym(this.allItems[i], searchTerm)) {
                        this.typeaheadResults.push(this.allItems[i])                        
                    }

                    if (this.typeaheadResults.length == this.typeaheadTake) {
                        break
                    }
                }
            }            
        }
    },
    methods: {
        checkFocusedIndex() {
            this.redirectToTypeaheadResult()
        },
        hasMatchingSynonym(searchItem, searchTerm) {
            if (searchItem && searchItem.Synonyms && searchItem.Synonyms.length > 0) {
                return searchItem.Synonyms.find(synonym => synonym.includes(searchTerm))
            }
            return false
        },
        clearKeyword() {
            this.keyword = ''
        }
    }
})