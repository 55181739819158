import { TimelineLite, TweenLite } from 'gsap'

const ANIM_DURATION_DEFAULT = 0.4

const newAnimation = (el, duration) =>
  el
    ? new TimelineLite()
        .set(el, { height: 'auto' })
        .from(el, duration / 2, {
          height: 0,
          overflow: 'hidden',
          visibility: 'hidden'
        })
        .fromTo(
          el,
          duration / 2,
          { opacity: 0 },
          { opacity: 1, height: 'auto', visibility: 'visible' }
        )
        .eventCallback('onReverseComplete', function () {
          TweenLite.set(el, { height: 0 })
        })
        .pause()
    : undefined

module.exports = Vue => {
  Vue.directive('Expand', {
    bind(el, { value, arg = ANIM_DURATION_DEFAULT }) {
        el.animation = newAnimation(el, arg)

        if (value) {
          el.animation.progress(1)
        } else {
          el.animation.progress(0)
        }
      },
      update(el, { value }) {
        if (value) {
          el.animation.play()
        } else {
          el.animation.reverse()
        }
      },
      unbind(el) {
        el.animation.kill()
      }
  })
}