import Vue from 'vue'
const SearchMixin = require('../mixins/Search')
const TypeaheadMixin = require('../mixins/Typeahead')

Vue.component('PeopleSearch', {
    props: {
        initialData: {
            type: String
        }
    },
    mixins: [
        SearchMixin({
            endpoint: '/api/sitecore/peoplesearch/get'
        }),
        TypeaheadMixin()
    ],
    data: () => ({
        activeLetter: undefined,
        crosslinks: [],
        featuredResults: [],        
        letters: [],
        selects: []
    }),    
    mounted() {
        let initialData = JSON.parse(this.initialData)
        this.crosslinks = initialData.crosslinks
        this.featuredResults = initialData.featuredResults
        this.results = initialData.results
        this.total = initialData.total
        this.filters = initialData.filters
        this.letters = initialData.letters
        this.selects = initialData.selects    

        if (this.hasAnyFilters) {
            this.keyword = this.getFilter('keyword')
            this.activeLetter = this.getFilter('letter')
            this._replaceUrl()
                .then(this.scrollTo('results', 75))
        }

        this.loadElements()
    },
    computed: {
        resultCounter() {
            if (this.results) {
                switch (this.currentCount) {
                    case 0:
                        return `${this.currentCount} results`
                    case 1:
                        return `Viewing ${this.currentCount} result`
                    default:
                        return `Viewing ${this.currentCount} of ${this.totalResults} results`
                }
            }
        },
        hasCrosslinks() {
            return this.crosslinks && this.crosslinks.length > 0
        },
        hasFeaturedResults() {
            return this.featuredResults && this.featuredResults.length > 0
        },
        totalFeaturedResults() {
            return this.hasFeaturedResults ? this.featuredResults.length : 0
        },        
        currentCount() {
            return (this.hasResults ? this.results.length : 0) + (this.hasFeaturedResults ? this.totalFeaturedResults : 0)
        },
        totalResults() {
            return this.total + this.totalFeaturedResults
        },
        stateToCache() {
            return {
                crosslinks: this.crosslinks,
                featuredResults: this.featuredResults,
                filters: this.filters,                
                results: this.results,
                total: this.total
            }
        }
    },
    methods: {
        loadElements() {
            let title = document.getElementById('title')
            let alphas = document.getElementById('alphas')
            let search = document.getElementById('search')
            let selects = document.getElementById('selects')

            setTimeout(() => {
                title.classList.add('search-filters__button-list--load')
            }, 100)
            setTimeout(() => {
                alphas.classList.add('search-filters__button-list--load')
            }, 100)
            setTimeout(() => {
                search.classList.add('search-filters__input-wrapper-people--load')
            }, 300)
            setTimeout(() => {
                selects.classList.add('search-filters__selects--load')
            }, 500)
        },
        formatPhone (number) {
            return number.split('T: ').pop()
        },
        loadMore() {
            document.activeElement.blur()
            this.page++
            this.setFilter('page', this.page)
                .then(this.search({ onSuccess: this.onSuccess, onError: this.onError }))
        },
        loadAll() {
            this.setDefaults()
            this.setFilter('page', this.page)
                .then(this.setFilter('viewall', true))
                .then(this.search({ onSuccess: this.onSuccess, onError: this.onError }))
        },
        keywordSearch() {
            //redirects to typeahead item if focused and presses enter
            //else do keyword search
            if (this.redirectToTypeaheadResult()) {
                return
            }

            this.resetTypeahead()
            if (this.keyword.length > 1) {
                this.setDefaults()
                this.setFilter('keyword', this.keyword)
                    .then(this.setFilter('page', this.page))
                    .then(this.setFilter('viewall', false))
                    .then(this.search({ onSuccess: this.onSuccess, onError: this.onError }))
                    .then(this.scrollTo('results', 20))
            }            
        },
        clearKeyword() {
            this.setDefaults()
            this.keyword = ''
            this.setFilter('keyword', undefined)
                .then(this.setFilter('page', this.page))
                .then(this.setFilter('viewall', false))
                .then(this.search({ onSuccess: this.onSuccess }))
        },
        letterSearch(letter) {
            if (this.activeLetter === letter) {
                this.activeLetter = undefined
                this.clearFilterAndSearch('letter')
            } else {
                this.setDefaults()
                this.activeLetter = letter
                this.setFilter('letter', letter)
                    .then(this.setFilter('viewall', false))
                    .then(this.search({ onSuccess: this.onSuccess, onError: this.onError }))
                    .then(this.scrollTo('results', 20))
            }            
        },
        selectSearch(key, value) {
            this.setDefaults()
            this.setFilter(key, value)
                .then(this.setFilter('page', this.page))
                .then(this.setFilter('viewall', false))
                .then(this.search({ onSuccess: this.onSuccess, onError: this.onError }))
                .then(this.scrollTo('results', 20))
        },
        isSelected(value) {
            return this.filters.some(function (filter) {
                return filter.value == value
            })
        },        
        isActiveFilter (key) {
            return this.getFilter(key)
        },
        clearPeopleFilters() {
            this.activeLetter = undefined
            this.clearFilters()
        },
        onSuccess(response) {
            let responseData = JSON.parse(response.data)
            this.crosslinks = responseData.crosslinks
            this.featuredResults = responseData.featuredResults
            this.results = this.results.concat(responseData.results)
            this.total = responseData.total
            return Promise.resolve()
        },
        onError(response) {            
            console.log(response)
            this.crosslinks = []
            this.featuredResults = []
            this.results = []
            this.total = 0
            return Promise.resolve()
        },
        typeaheadSearch(event) {            
            if (this.keyword.length >= this.typeaheadMin) {
                if (this.isValidKeyCode(event.keyCode)) {                    
                    this.inPageSearch(
                        { onSuccess: this.onTypeaheadSuccess, onError: this.onTypeaheadError },
                        `/api/sitecore/peoplesearch/typeahead?keyword=${encodeURIComponent(this.keyword)}&take=${this.typeaheadTake}`,
                        false
                    )
                }
            } else {
                this.typeaheadResults = []
            }
        },    
        _setStateFromCache(pathname) {
            return ({ state }) => {
                if (window.location.pathname === pathname && state) {
                    this.filters = state.filters                    
                    this.crosslinks = state.crosslinks
                    this.featuredResults = state.featuredResults
                    this.results = state.results
                    this.total = state.total
                    this.keyword = this.getFilter('keyword')
                    this.page = this.getFilter('page')
                } else {
                    this.filters = []                    
                    this.crosslinks = []
                    this.featuredResults = []
                    this.results = []
                    this.total = 0
                    this.keyword = ''
                    this.page = 1
                }
            }
        }
    }
})
