import Vue from 'vue'
const SearchMixin = require('../mixins/Search')

Vue.component('RelatedEvents', {
    props: ['initialData', 'futureRange'],
    mixins: [
        SearchMixin({
            endpoint: '/api/sitecore/articlesearch/get',
            useHistoryListener: false
        })
    ], 
    data: () => ({
        intialResults: [],
        range: undefined,
        wasToggled: false
    }),
    mounted() {        
        let initialData = JSON.parse(this.initialData)
        this.results = this.initialResults = initialData.results
        this.total = initialData.total
        this.filters = initialData.filters
        this.range = this.futureRange
        
        this.$root.$on('search-deactivated', () => {                   
            this.results = this.initialResults
        })
    },
    computed: {
        isUpcoming() {
            return this.range === this.futureRange
        }
    },
    methods: {        
        toggleEvents(range) {            
            this.page = 1
            this.range = range
            this.wasToggled = true            
            this.setFilter('range', this.range)
                .then(this.executeSearch())
        },
        loadMore() {
            document.activeElement.blur()
            this.page++
            this.executeSearch()
        },
        executeSearch() {
            this.setFilter('page', this.page)
                .then(this.inPageSearch({
                    onSuccess: this.onSuccess,
                    onError: this.onError
                }))
        },
        onSuccess(response) {
            let responseData = JSON.parse(response.data)            
            this.results = this.wasToggled
                ? responseData.results
                : this.results.concat(responseData.results)
            this.total = responseData.total
            this.wasToggled = false
            return Promise.resolve()
        },
        onError(response) {
            let responseData = JSON.parse(response.data)
            console.log(responseData.msg)            
            this.results = []
            this.total = 0
            return Promise.resolve()
        }
    }
})