import Vue from 'vue'

Vue.component('EmailSubscribe', {
    props: ['redirectUrl'],
    data: () => ({
        email: ''
    }),    
    methods: {        
        submitEmail() {
            if (this.validateEmail()) {
                window.location.href = `${this.redirectUrl}?email=${this.email}`
            }            
        },
        validateEmail() {
            return this.email.length > 0            
        }
    }
})