import Vue from 'vue'
const SearchMixin = require('../mixins/Search')

Vue.component('RelatedArticles', {
    props: ['initialData'],
    mixins: [
        SearchMixin({
            endpoint: '/api/sitecore/articlesearch/get',
            useHistoryListener: false
        })
    ], 
    data: () => ({
        intialResults: []        
    }),
    mounted() {        
        let initialData = JSON.parse(this.initialData)
        this.results = this.initialResults = initialData.results
        this.total = initialData.total
        this.filters = initialData.filters        
        
        this.$root.$on('search-deactivated', () => {            
            this.results = this.initialResults
        })
    },
    computed: {

    },
    methods: {
        loadMore() {
            document.activeElement.blur()
            this.page++
            this.executeSearch()
        },
        executeSearch() {
            this.setFilter('page', this.page)
                .then(this.inPageSearch({
                    onSuccess: this.onSuccess,
                    onError: this.onError
                }))
        },
        onSuccess(response) {
            let responseData = JSON.parse(response.data)
            this.results = this.results.concat(responseData.results)
            this.total = responseData.total
            return Promise.resolve()
        },
        onError(response) {
            let responseData = JSON.parse(response.data)
            console.log(responseData.msg)            
            this.results = []
            this.total = 0
            return Promise.resolve()
        }
    }
})