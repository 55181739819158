﻿import Vue from 'vue'
import axios from 'axios'

Vue.component('FormComponent', {
    props: {
        endpoint: String,
        formId: String,
        hasCheckboxes: Boolean
    },
    data() {
        return {
            fields: {},
            errors: [],
            isInit: true,
            success: false,
            submitted: false,            
            showFormDisclaimer: false
        }
    },
    created() {
        this.prepopulateFields()
    },
    mounted() {
        this.fields.FormId = this.formId
    },
    methods: {
        submitForm() {
            axios.post(this.endpoint, {
                fields: JSON.stringify(this.fields),
                recaptchaResponse: grecaptcha.getResponse()
            })
            .then(data => {
                this.success = true
                this.submitted = true
                this.clearForm()
            })
            .catch(err => {
                this.success = false
                this.submitted = true
                this.clearForm()
            })
        },
        validateInput() {
            let valid = true

            this.errors = []

            var elements = this.$el.querySelectorAll('.form__component-field')

            for (var x = 0; x < elements.length; x++) {
                const isRequired = elements[x].getAttribute('data-required') === null ? false : elements[x].getAttribute('data-required').toLowerCase() == 'true'
                if (!elements[x].value && !isRequired) {
                    //  in the case of no value for optional fields just go to the next element
                    continue
                } else if (!elements[x].value) {
                    this.errors.push(elements[x].getAttribute('data-field-key'))
                    valid = false
                } else {
                    if (elements[x].getAttribute('data-reg-test')) {
                        var regex = RegExp(elements[x].getAttribute('data-reg-test'))
                        if (!regex.test(elements[x].value)) {
                            this.errors.push(elements[x].getAttribute('data-field-key'))
                            valid = false
                        }
                    }
                }
            }

            return valid
        },
        isInvalid(field) {
            var parser = new DOMParser
            var parsedFieldContent = parser.parseFromString(field, 'text/html')
            if (field && parsedFieldContent && this.errors.includes(parsedFieldContent.body.textContent) && !this.isInit) {
                return true
            }
            return false
        },
        clearForm() {
            this.fields = []
        },
        validateCheckboxes() {
            let valid = false
            const checkboxes = document.querySelectorAll(".form__component-field-checkboxes")
            checkboxes.forEach(el => {
                if (el.checked == true) {
                    valid = true
                }
            })
            if (!valid) {
                // push error for main div on checkboxes
                const checkboxes = document.querySelector(".checkboxes")
                this.errors.push(checkboxes.getAttribute("data-field-key"))
            }
            return valid
        },
        validateLegalAgreement() {
            let valid = false
            const legalAgreement = document.querySelector(".legalAgreement")
            if (legalAgreement.checked) {
                valid = true
            } else {
                this.errors.push(legalAgreement.getAttribute("data-field-key"))
            }
            return valid
        },
        prepopulateFields() {
            const urlParams = new URLSearchParams(window.location.search)
            const email = urlParams.get('email')
            if (email) {
                this.fields['Email Address'] = email
            }
        },
        onSubmitForm() {
            this.isInit = false
            if (this.validateInput() && this.validateLegalAgreement() && (!this.hasCheckboxes || this.validateCheckboxes())) {
                this.showFormDisclaimer = true
            }
        },
        accept() {
            this.submitForm()
            this.showFormDisclaimer = false
        },
        decline() {
            this.showFormDisclaimer = false
        }
    }
})