import Vue from 'vue'

Vue.component('ServiceHero', {
  mounted () {
    this.loadElements()
  },
  methods: {
    loadElements() {
      let title = document.getElementById('title')
      let featured = document.getElementById('featured')

      setTimeout(() => {
          title.classList.add('service-landing-hero__title--load')
      }, 100)
      setTimeout(() => {
        featured.classList.add('service-landing-hero__featured--load')
      }, 300)
    }
  }
})