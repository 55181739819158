import Vue from 'vue'
const SearchMixin = require('../mixins/Search')

Vue.component('ArticleSearch', {
    props: ['initialData'],
    mixins: [
        SearchMixin({
            endpoint: '/api/sitecore/articlesearch/get'
        })
    ],    
    data: () => ({
        selects: []
    }),    
    mounted() {
        let initialData = JSON.parse(this.initialData)        
        this.results = initialData.results
        this.total = initialData.total
        this.filters = initialData.filters        
        this.selects = initialData.selects   
        
        if (this.hasAnyFilters) {            
            this.keyword = this.getFilter('keyword')
            this._replaceUrl()
            this.scrollTo('results', 0)
        }
    },    
    methods: {
        loadMore() {
            document.activeElement.blur()
            this.page++
            this.setFilter('page', this.page)
                .then(this.search({ onSuccess: this.onSuccess }))
        },
        loadAll() {
            this.setDefaults()
            this.setFilter('page', this.page)
                .then(this.setFilter('viewall', true))
                .then(this.search({ onSuccess: this.onSuccess }))
        },
        clearKeyword() {
            this.setDefaults()
            this.keyword = ''
            this.setFilter('keyword', undefined)
                .then(this.setFilter('page', this.page))
                .then(this.setFilter('viewall', false))
                .then(this.search({ onSuccess: this.onSuccess }))

            if (!this.hasAnyFilters) {
                this.$root.$emit('search-deactivated', this.filters)
            }
        },
        keywordSearch() {            
            if (this.keyword.length > 1) {
                this.$root.$emit('search-activated', this.filters)
                this.setDefaults()
                this.setFilter('keyword', this.keyword)
                    .then(this.setFilter('page', this.page))
                    .then(this.setFilter('viewall', false))
                    .then(this.search({ onSuccess: this.onSuccess }))
            }
        },        
        selectSearch(key, value) {
            this.$root.$emit('search-activated', this.filters)
            this.setDefaults()
            this.setFilter(key, value)
                .then(this.setFilter('page', this.page))
                .then(this.setFilter('viewall', false))
                .then(this.search({ onSuccess: this.onSuccess }))
        },
        isActiveFilter(key) {
            return this.getFilter(key)
        },
        clearFilterAndSearch(key) {
            this.setDefaults()
            this.setFilter(key, undefined)
                .then(this.setFilter('page', this.page))
                .then(this.setFilter('viewall', false))
                .then(this.search({ onSuccess: this.onSuccess }))

            if (!this.hasAnyFilters) {
                this.$root.$emit('search-deactivated', this.filters)
            }
        },
        clearFiltersAndSearch() {
            this.setDefaults()
            this.clearAllFilters()            
            this.updateUrl()

            this.$root.$emit('search-deactivated', this.filters)
        },
        onSuccess(response) {
            let responseData = JSON.parse(response.data)            
            this.results = this.results.concat(responseData.results)
            this.total = responseData.total
            this.response = responseData
            return Promise.resolve()
        },
        onSearchError(response) {            
            console.log(response)            
            this.results = []
            this.total = 0
            return Promise.resolve()
        },
        _setStateFromCache(pathname) {
            return ({ state }) => {
                if (window.location.pathname === pathname && state) {
                    this.filters = state.filters
                    this.results = state.results
                    this.total = state.total
                    this.keyword = this.getFilter('keyword')
                    this.page = this.getFilter('page')
                } else {
                    this.filters = []
                    this.results = []
                    this.total = 0
                    this.keyword = ''
                    this.page = 1
                }
                if (!this.hasAnyFilters) {
                    this.$root.$emit('search-deactivated', this.filters)
                }
            }
        }
    }
})