import Vue from 'vue'

Vue.component('SideRailCta', {
  mounted () {
    this.stickyPosition()
  },
  methods: {
    stickyPosition () {
      if (window.innerWidth > 1024) {
        window.addEventListener('scroll', () => {
          let ctas = document.querySelector('.short-hero__ctas')
          if ((window.innerHeight + window.scrollY + 50) >= document.body.scrollHeight) {
            console.log('reached the bottom')
            ctas.classList.add('short-hero__ctas--bottom')
          } else {
            ctas.classList.remove('short-hero__ctas--bottom')
          }
        })
      }
    }
  }
})