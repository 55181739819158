import Vue from 'vue'
const ScrollMixin = require('../mixins/Scroll')

Vue.component('MegaMenu', {
    mixins: [ ScrollMixin() ],
    data: () => ({
        activeCategory: null
    }),
    mounted () {
        this.$root.$on('pass-back', (category) => {
            this.activeCategory = category            
        })
    },
    computed: {
        path() {
            return window.location.pathname
        }
    },
    methods: {
        openMegaMenu (category) {
            this.$root.$emit('open-category', category)
            this.disableScroll()
        },
        closeMegaMenu () {            
            this.$root.$emit('close-category', undefined)            
            this.enableScroll()
        },
        categoryIsOpen (category) {
            return this.activeCategory === category
        },        
        activeLinkClass(url) {
            return this.path.indexOf(url) === 0 ? 'global-header__link--active' : ''
        } 
    }
})