﻿import Vue from 'vue'

Vue.component('ShareTools', {
    data() {
        return {
            hide: true
        }
    },
    methods: {
        expandIcons() {
            this.hide = !this.hide;
        }
    }
})