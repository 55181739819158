import Vue from 'vue'

Vue.component('FlexPageNav', {
  data: () => ({
    isOpen: false
  }),
  computed: {
    isModalOpen () {
      return this.isOpen
    }
  },
  methods: {
    isActiveSub (url) {
      return window.location.pathname === url
    },
    isActiveDropdown (url) {
      return window.location.pathname.indexOf(url) === 0
    },
    openModal () {
      this.isOpen = true
    },
    closeModal () {
      this.isOpen = false
    }
  }
})