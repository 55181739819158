import Vue from 'vue'
import Swiper from 'swiper'
const SearchMixin = require('../mixins/Search')

Vue.component('SimpleTileGrid', {
    props: ['initialData'],
    mixins: [
        SearchMixin({
            endpoint: '/api/sitecore/experiencesearch/get',
            updateUrl: false
        })
    ],    
    data: () => ({
        isExpanded: false,
        activeSlide: 0
    }),    
    mounted() {
        let initialData = JSON.parse(this.initialData)
        this.results = initialData.results        
        this.total = initialData.total
        this.filters = initialData.filters

        setTimeout(() => {
            this.createSwiper()
        }, 1000)
    },
    methods: {
        toggleContent () {
            this.isExpanded = !this.isExpanded
        },
        createSwiper () {
            this.mySwiper = new Swiper('.swiper-experience', {
                slidesPerView: 1,
                speed: 400
            })
            this.mySwiper.on('slideChange', (event) => {
                this.activeSlide = event.activeIndex
            })
        },
        nextSlide() {
            this.mySwiper.slideNext()
        },
        prevSlide() {
            this.mySwiper.slidePrev()
        },
        isActiveSlide (index) {
            return this.activeSlide === index
        },
        changeSlide (index) {
            this.mySwiper.slideTo(index)
            this.activeSlide = index
        },
        loadMore() {
            document.activeElement.blur()
            this.page++
            this.setFilter('page', this.page)
                .then(this.inPageSearch({
                    onSuccess: this.onSuccess,
                    onError: this.onError
                }))
        },
        onSuccess(response) {
            let responseData = JSON.parse(response.data)            
            this.results = this.results.concat(responseData.results)
            this.total = responseData.total
            return Promise.resolve()
        },
        onError(response) {
            let responseData = JSON.parse(response.data)
            console.log(responseData.msg)
            this.featuredResults = []
            this.results = []
            this.total = 0
            return Promise.resolve()
        }
    }
})