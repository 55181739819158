import Vue from 'vue'

Vue.component('PeopleHero', {
  data: () => ({
    load: false
  }),
  mounted () {
    setTimeout(() => {
      this.loadImage()
      this.load = true
    }, 10)
  },
  computed: {
    hasLoaded () {
      return this.load
    }
  },
  methods: {
    loadImage () {
      let img = document.querySelector('.detail-hero__image-inner')
      if (img) {
        img.classList.add('detail-hero__image-inner--load')
      }
    }
  }
})