import Vue from 'vue'

Vue.component('CredentialGroup', {
  data: () => ({
    isExpanded: false
  }),
  methods: {
    toggleContent () {
      this.isExpanded = !this.isExpanded

      const slideDown = element => element.style.height = `${element.scrollHeight}px`
      const slideUp = element => element.style.height = 0

      let content = this.$refs.additionalContent
      
      if (content.getBoundingClientRect().height === 0) {
        document.activeElement.blur()
        slideDown(content)
      } else {
        document.activeElement.blur()
        slideUp(content)
      }
    }
  }
})