import Vue from 'vue'

Vue.component('StickyMenu', {
    data: () => ({
        activeSection: 0,
        setLoad: true
    }),
    computed: {
        sectionCount() {
            return this.$attrs.sections
        }
    },
    mounted() {
        setTimeout(() => {
            this.setLoad = false
        }, 1000)
        this.stickyScroll()
    },
    methods: {
        isActiveSection(index) {
            return this.activeSection === index
        },
        scrollToSection(id) {
            document.getElementById(id).scrollIntoView({
                behavior: 'smooth'
            })
        },
        stickyScroll() {
            if (window.innerWidth > 1024) {
                let lastScrollTop = 0
                window.addEventListener('scroll', () => {
                    let st = window.pageYOffset
                    let length = this.sectionCount

                    if (this.activeSection === (length - 1)) {
                        if (st < lastScrollTop && document.querySelectorAll('.sticky-section')[this.activeSection - 1].getBoundingClientRect().bottom > 300) {
                            this.activeSection -= 1
                        }
                    } else if (this.activeSection === 0 && st > lastScrollTop && document.querySelectorAll('.sticky-section')[1].getBoundingClientRect().top < 350) {
                        this.activeSection += 1
                    } else if (this.activeSection < length && this.activeSection > 0 && st > lastScrollTop) {
                        let stickySection = document.querySelectorAll('.sticky-section')[this.activeSection + 1]
                        if (stickySection && stickySection.getBoundingClientRect().top < 350) {
                            this.activeSection += 1
                        }
                    } else if (this.activeSection <= (length - 2) && this.activeSection > 1 && st < lastScrollTop) {
                        let stickySection = document.querySelectorAll('.sticky-section')[this.activeSection - 1]
                        if (stickySection && stickySection.getBoundingClientRect().bottom > 350) {
                            this.activeSection -= 1
                        }
                    } else if (this.activeSection === 1 && st < lastScrollTop) {
                        // BC top section is huge and encompasses everything...
                        let stickySection = document.querySelectorAll('.sticky-section')[0]
                        if (stickySection && stickySection.getBoundingClientRect().bottom > 1400) {
                            this.activeSection = 0
                        }
                    }
                    lastScrollTop = st <= 0 ? 0 : st
                })
            }
        }
    }
})