const PERCENTAGE_FROM_BOTTOM = 0.15

const isInView = el => {
  const viewportTop = el.getBoundingClientRect().top
  const bodyTop = document.body.getBoundingClientRect().top
  const windowHeight = window.innerHeight

  if (viewportTop < 0) {
    return true
  } else if (viewportTop > windowHeight) {
    return false
  } else if (bodyTop === 0 && viewportTop < windowHeight) {
    return true
  } else if (
    (windowHeight - viewportTop) / windowHeight >
    PERCENTAGE_FROM_BOTTOM
  ) {
    return true
  } else {
    return false
  }
}

const addViewClass = el => {
  if (isInView(el) && !el.classList.contains('scroll-appear--visible')) {
    el.classList.add('scroll-appear--visible')
  }
}

module.exports = Vue => {
  Vue.directive('ScrollAppear', {
    inserted (el, { expression }) {
      expression ? (expression = expression.replace(/^'|'$/g, '')) : false // strip out the string, the v-directive syntax doesn't seem to allow passing in a string cause it looks for an actual variable
      const animate = expression || 'default'
      el.classList.add(`scroll-appear`)
      el.classList.add(`scroll-appear--${animate}`)
      el.handler = evt => addViewClass(el)

      addViewClass(el)

      window.addEventListener('scroll', el.handler)
      window.addEventListener('resize', el.handler)
    },
    unbind (el) {
      window.removeEventListener('scroll', el.handler)
      window.removeEventListener('resize', el.handler)
    }
  })
}
