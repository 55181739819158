import Vue from 'vue'

Vue.component('LoadMore', {
    props: {
        take: {
            type: Number,
            default: 4
        },
        total: {
            type: Number,
            default: 0
        }
    },
    data: () => ({
        currentCount: 0
    }),
    mounted () {
        this.currentCount = this.take
    },
    computed: {
        hasMore() {
            return this.total > this.currentCount            
        }
    },
    methods: {
        viewMore () {
            this.currentCount += this.take
        }
    }
})