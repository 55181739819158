import Vue from 'vue'
const SearchMixin = require('../mixins/Search')
const TypeaheadMixin = require('../mixins/Typeahead')

Vue.component('GlobalHeader', {
    mixins: [
        SearchMixin({
            endpoint: '/api/sitecore/globalsearch/get',
            appendResults: false
        }),
        TypeaheadMixin()
    ],  
    data: () => ({
        searchOpen: false,
        menuOpen: false,        
        activeMegaMenuCategory: null,
        scrollingUp: false        
    }),
    mounted() {        
        this.filters = [
            { key: 'keyword', value: this.keyword },
            { key: 'take', value: this.typeaheadTake }
        ]

        this.$root.$on('open-category', (category) => {
            this.activeMegaMenuCategory = category
            this.$root.$emit('pass-back', this.activeMegaMenuCategory)
        })

        this.$root.$on('close-category', () => {
            this.activeMegaMenuCategory = null
            this.$root.$emit('pass-back', this.activeMegaMenuCategory)
        })

        this.handleScroll()
    },
    computed: {
        searchIsOpen () {
            return this.searchOpen
        },
        menuIsOpen () {
            return this.menuOpen
        },                
        megaMenuIsOpen ()  {
            return this.activeMegaMenuCategory !== null
        },
        scrollingUpPage () {
            return this.scrollingUp
        },
        path() {
            return window.location.pathname
        }
    },
    methods: {
        typeaheadSearch(event) {
            if (this.keyword.length >= this.typeaheadMin) {
                if (this.isValidKeyCode(event.keyCode)) {
                    this.setFilter('keyword', this.keyword)
                        .then(this.inPageSearch(
                            { onSuccess: this.onTypeaheadSuccess, onError: this.onTypeaheadError },
                            '',
                            false
                        ))
                }
            } else {
                this.typeaheadResults = []
            }
        },
        openSearch () {
            this.searchOpen = true                        
        },
        closeSearch() {
            setTimeout(() => {
                this.searchOpen = false
                this.keyword = ''
            }, 100)               
        },
        openMenu () {
            this.menuOpen = true
            document.body.style.overflow = 'hidden'
        },
        closeMenu () {
            this.menuOpen = false
            document.body.style.overflow = 'auto'            
        },
        siteSearch() {
            //redirects to typeahead item if focused and presses enter
            //else do keyword site search search
            if (this.redirectToTypeaheadResult()) {
                return
            }

            //todo: update this to not be hard coded
            window.location.href = `/site-search?keyword=${encodeURIComponent(this.keyword)}`
        },
        activeLinkClass(url) {
            return url.length > 0 && this.path.indexOf(url) === 0 ? 'global-header__link--active' : ''
        },
        handleScroll() {
            let lastScrollTop = 0
            window.addEventListener('scroll', () => {
                let offset = window.pageYOffset
                if (offset < lastScrollTop && offset !== 0) {
                    this.scrollingUp = true
                    console.log(this.scrollingUp)
                } else {
                    this.scrollingUp = false
                    console.log(this.scrollingUp)
                }
            lastScrollTop = offset <= 0 ? 0 : offset
            })
          }        
    },
    //watch: {
    //    keyword: function () {
    //        if (this.keyword && this.keyword.length >= this.typeaheadMin) {                                
    //            if (this.isValidKeyCode(event.keyCode)) {
    //                this.setFilter('keyword', this.keyword)
    //                    .then(this.inPageSearch)
    //                //this.inPageSearch(
    //                //    { onSuccess: this.onTypeaheadSuccess, onError: this.onTypeaheadError },
    //                //    false
    //                //)
    //            }
    //        }      
    //    }
    //}
})