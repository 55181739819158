import axios from 'axios'
import { TweenMax } from 'gsap'
import 'gsap/ScrollToPlugin'

const CancelToken = axios.CancelToken
let cancelSource

const SearchMixin = ({ endpoint = undefined, useHistoryListener = true, appendResults = true } = {}) => {
    if (!endpoint) {
        console.warn('WARN', 'No endpoint provided, cannot start mixin.')
        return {}
    }
    return {
        data: () => ({
            paginationFilterNames: ['page'],
            isSearching: false,
            results: [],
            filters: [],         
            keyword: '',
            page: 1,
            total: 0,
            endpoint: endpoint,
            useHistoryListener: useHistoryListener,
            appendResults: appendResults
        }),
        mounted() {
            if (this.useHistoryListener) {
                const pathname = window.location.pathname
                this._setupHistoryListener(pathname)
            }
        },
        destroyed() {
            const pathname = window.location.pathname
            this._removeHistoryListener(pathname)
        },
        computed: {
            hasMore() {
                return this.results && this.results.length < this.total
            },
            hasResults() {
                return this.results && this.results.length > 0
            },
            currentCount() {
                return this.hasResults ? this.results.length : 0
            },
            totalResults() {
                return this.total
            },
            resultCounter() {
                if (this.results) {
                    switch (this.results.length) {
                        case 0:
                            return `${this.currentCount} results`
                        case 1:
                            return `Viewing ${this.currentCount} result`
                        default:
                            return `Viewing ${this.currentCount} of ${this.totalResults} results`
                    }
                }
            },
            filtersAsList() {
                return Object.keys(this.filters)
                    .map(key => ({ key, value: this.filters[key].value, label: this.filters[key].label }))
            },
            hasAnyFilters() {
                return this.filters.some(function (filter) {
                    return filter.value && (filter.key != 'page' && filter.key != 'take')
                })
            },
            activeFilterList() {
                return this.filtersAsList
                    .filter(({ key }) => this.paginationFilterNames.indexOf(key) === -1)
                    .filter(({ value }) => typeof value === 'string' && value !== "")
            },
            stateToCache() {
                return {
                    filters: this.filters,                    
                    results: this.results,
                    total: this.total
                }
            },
            queryString() {
                const filterString = this.filters
                    .filter(x => x.value)
                    .map(this._normalizeArray)
                    .map(({ key, value }) => `${key}=${encodeURIComponent(value)}`)
                    .join('&')
                return filterString ? `?${filterString}` : ''
            },
            apiUrl() {
                return (!this.endpoint) ? undefined
                    : (this.endpoint.toLowerCase().indexOf('http') === 0)
                        ? [this.endpoint, this.queryString].join('')
                        : [window.location.origin, this.endpoint, this.queryString].join('')
            },
            fullUrl() {
                return [
                    window.location.origin,
                    window.location.pathname,
                    this.hasAnyFilters ? this.queryString : ''
                ].join('')
            },
            hasKeyword() {
                let value = this.getFilter('keyword')
                return value && value.length > 0
            }
        },
        methods: {
            setDefaults() {
                this.results = []
                this.page = 1
            },
            getFilter(key) {
                let found = this.filters.find(filter => filter.key === key)
                return found ? found.value : undefined
            },
            setFilter(key, value) {                
                for (var i in this.filters) {
                    if (this.filters[i] && this.filters[i].key == key) {
                        this.filters[i].value = value                        
                        break
                    }
                }
                return Promise.resolve()
            },
            clearFilter(key) {
                this.setFilter(key, undefined)
                this._replaceUrl()
            },
            clearFilterAndSearch(key) {
                this.setDefaults()
                this.setFilter(key, undefined)
                    .then(this.setFilter('page', this.page))
                    .then(this.setFilter('viewall', false))
                    .then(this.search({ onSuccess: this.onSuccess }))
            },
            clearFilters() {
                this.clearAllFilters()
                this._replaceUrl()
                    .then(window.scrollTo({ top: 0, left: 0, behavior: 'smooth'}))
            },
            clearAllFilters() {
                this.results = []
                this.total = 0
                this.keyword = ''
                this.filters.forEach(function (filter) {
                    switch (filter.key) {
                        case 'page':
                            filter.value = 1
                            break
                        case 'take':
                            break
                        default:
                            filter.value = undefined
                            break
                    }
                })
            },
            isSelected(value) {
                return this.filters.some(filter => filter.value == value)
            },            
            updateUrl() {
                window.history.pushState(this.stateToCache, null, this.fullUrl)
                return Promise.resolve(this.stateToCache)
            },
            search({ onSuccess, onError } = {}) {
                this.isSearching = true
                return axios.get(this.apiUrl)
                    .then(onSuccess)
                    .catch(onError)
                    .then(this.updateUrl)
                    .then(state => {
                        this.isSearching = false
                        return state
                    })
            },
            inPageSearch({ onSuccess, onError } = {}, apiUrlOverride = '', updateIsSearching = true) {
                if (cancelSource) {
                    cancelSource()
                }

                if (updateIsSearching) {
                    this.isSearching = true
                }                

                return axios.get(apiUrlOverride ? apiUrlOverride : this.apiUrl, {
                    cancelToken: new CancelToken(function executor(c) {
                        cancelSource = c
                    })
                })
                .then(onSuccess || this.onSuccess)
                .catch(onError || this.onError)
                .then(state => {
                    this.isSearching = false
                    return state
                })
            },
            scrollTo({ id = 'results', offset = 0 } = {}) {
               const error = `Could not scroll, no element has id ${id}`
               return new Promise((resolve, reject) =>
                   (document.getElementById(id)) ?
                       TweenMax.to(window, 1.2, {
                           scrollTo: { y: document.getElementById(id), offsetY: offset },
                           onComplete: resolve
                       }) : reject(error)
               )
            },
            onSuccess(response) {
                let responseData = JSON.parse(response.data)
                this.results = this.appendResults ? this.results.concat(responseData.results) : responseData.results
                this.total = responseData.total
                cancelSource = undefined
                return Promise.resolve()
            },
            onError(response) {
                console.log(response)
                this.results = []
                this.total = 0
                return Promise.resolve()
            },
            _replaceUrl() {
                window.history.replaceState(this.stateToCache, null, this.fullUrl)
                return Promise.resolve(this.stateToCache)
            },
            _normalizeArray: (value) => (value instanceof Array) ? value.join(',') : value,
            _setStateFromCache(pathname) {
                return ({ state }) => {
                    if (window.location.pathname === pathname && state) {
                        this.filters = state.filters
                        this.results = state.results
                        this.total = state.total
                        this.keyword = this.getFilter('keyword')
                        this.page = this.getFilter('page')
                    } else {
                        this.filters = []
                        this.results = []
                        this.total = 0
                        this.keyword = ''
                        this.page = 1
                    }
                }
            },
            _setupHistoryListener(pathname) {
                window.addEventListener('popstate', this._setStateFromCache(pathname))
            },
            _removeHistoryListener(pathname) {
                window.removeEventListener('popstate', this._setStateFromCache(pathname))
            }
        }
    }
}

module.exports = SearchMixin