﻿import Vue from 'vue'

Vue.component('FeaturedPeople', {
    props: ['initialData'],
    data: () => ({
        results: []
    }),
    mounted() {
        let initialData = JSON.parse(this.initialData)        
        this.results = initialData.results
    },
    methods: {
        formatPhone(number) {
            return number.split('T: ').pop()
        }
    }
})