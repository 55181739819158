import Vue from 'vue'
import {VueMasonryPlugin} from './directives/Masonry'
import Expand from './directives/Expand'
import ScrollAppear from './directives/ScrollAppear'
import CredentialGroup from './components/CredentialGroup'
import EmailSubscribe from './components/EmailSubscribe'
import ExpandedRte from './components/ExpandedRte'
import FlexibleCarousel from './components/FlexibleCarousel'
import FlexPageNav from './components/FlexPageNav'
import GlobalHeader from './components/GlobalHeader'
import HomeHero from './components/HomeHero'
import HomeNewsCarousel from './components/HomeNewsCarousel'
import LocationsFauxDropdown from './components/LocationsFauxDropdown'
import PeopleCarousel from './components/PeopleCarousel'
import PeopleHero from './components/PeopleHero'
import RelatedArticles from './components/RelatedArticles'
import RelatedEvents from './components/RelatedEvents'
import ServiceAccordion from './components/ServiceAccordion'
import ServiceHero from './components/ServiceHero'
import ServicesSort from './components/ServicesSort'
import SideRailCta from './components/SideRailCta'
import SimpleTileGrid from './components/SimpleTileGrid'
import SiteSearch from './components/SiteSearch'
import StalkerGradient from './components/StalkerGradient'
import StickyEmail from './components/StickyEmail'
import StickyMenu from './components/StickyMenu'
import StickyServiceMenu from './components/StickyServiceMenu'
import SimpleTypeahead from './components/SimpleTypeahead'
import ArticleSearch from './components/ArticleSearch'
import PeopleSearch from './components/PeopleSearch'
import ExperienceSearch from './components/ExperienceSearch'
import SearchMixin from './mixins/Search'
import ScrollMixin from './mixins/Scroll'
import TypeaheadMixin from './mixins/Typeahead'
import FormsComponent from './components/FormsComponent'
import FeaturedPeople from './components/FeaturedPeople'
import ShareTools from './components/ShareTools'
import EmailDisclaimer from './components/EmailDisclaimer'
import MegaMenu from './components/MegaMenu'
import LoadMore from './components/LoadMore'
import Accordion from './components/Accordion'

Vue.use(VueMasonryPlugin)
Vue.use(Expand)
Vue.use(ScrollAppear)

module.exports = new Vue({ 
    el: '#app',
    data: {
        showEmailDisclaimer: false,
        activeEmail: undefined,
        activeSubject: undefined,
        activeBody: undefined,
    },
    methods: {
        openEmailDisclaimer(email, subject, body) {
            this.activeEmail = email
            this.activeSubject = subject
            this.activeBody = body
            this.showEmailDisclaimer = true
        }
    }
})