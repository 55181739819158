const ScrollMixin = () => {
    return {
        data: () => ({            
            
        }),
        mounted() {
            
        },
        destroyed() {
            
        },
        computed: {            

        },
        methods: {
            disableScroll() {
                document.body.classList.add("no-scroll")
            },
            enableScroll() {
                document.body.classList.remove("no-scroll")
            }
        }
    }
}

module.exports = ScrollMixin