import Vue from 'vue'

Vue.component('StickyServiceMenu', {
    data: () => ({
        activeSection: 0,
        sorting: 'category'
    }),
    computed: {
        sectionCount() {
            return this.$attrs.sections
        },
        isCategory() {
            return this.sorting === 'category'
        }
    },
    mounted() {
        this.stickyScroll()

        this.$root.$on('change-sort', (sortOption) => {
            this.sorting = sortOption
        })
    },
    methods: {
        isActiveSection(index) {
            return this.activeSection === index
        },
        scrollToSection(id) {
            document.getElementById(id).scrollIntoView({
                behavior: 'smooth'
            })
        },
        stickyScroll() {
            if (window.innerWidth > 1024) {
                let lastScrollTop = 0
                window.addEventListener('scroll', () => {
                    let content = document.getElementById('content')
                    let hero = document.getElementById('hero')
                    let menu = document.getElementById('sticky')
                    let bottom = hero.getBoundingClientRect().bottom
                    let contentBottom = content.getBoundingClientRect().bottom

                    if (bottom < 0 && contentBottom > 700) {
                        let st = window.pageYOffset
                        let length = this.sectionCount

                        menu.classList.add('sticky-menu-services--fixed')

                        if (menu.classList.contains('sticky-menu-services--bottom')) {
                            menu.classList.remove('sticky-menu-services--bottom')
                        }

                        if (this.activeSection === (length - 1)) {
                            if (st < lastScrollTop && document.querySelectorAll('.sticky-section')[this.activeSection - 1].getBoundingClientRect().bottom > 300) {
                                this.activeSection -= 1
                            }
                        } else if (this.activeSection === 0 && st > lastScrollTop && document.querySelectorAll('.sticky-section')[1].getBoundingClientRect().top < 350) {
                            this.activeSection += 1
                        } else if (this.activeSection < length && this.activeSection > 0 && st > lastScrollTop) {
                            if (document.querySelectorAll('.sticky-section')[this.activeSection + 1].getBoundingClientRect().top < 350) {
                                this.activeSection += 1
                            }
                        } else if (this.activeSection <= (length - 2) && this.activeSection > 1 && st < lastScrollTop) {
                            if (document.querySelectorAll('.sticky-section')[this.activeSection - 1].getBoundingClientRect().bottom > 350) {
                                this.activeSection -= 1
                            }
                        } else if (this.activeSection === 1 && st < lastScrollTop) {
                            // BC top section is huge and encompasses everything...
                            let stickySection = document.querySelectorAll('.sticky-section')[0]
                            if (stickySection && stickySection.getBoundingClientRect().bottom > 1400) {
                                this.activeSection = 0
                            }
                        }
                        lastScrollTop = st <= 0 ? 0 : st
                    } else if (bottom < 0 && contentBottom < 700) {
                        if (menu.classList.contains('sticky-menu-services--fixed')) {
                            menu.classList.remove('sticky-menu-services--fixed')
                            menu.classList.add('sticky-menu-services--bottom')
                        }
                    } else {
                        if (menu.classList.contains('sticky-menu-services--fixed')) {
                            menu.classList.remove('sticky-menu-services--fixed')
                        }
                    }
                })
            }
        }
    }
})