import Vue from 'vue'

Vue.component('Accordion', {
    data:() => ({
        openIndex: null,
        openAccordion: null
    }),

    methods: {
        isContainerExpanded(index) {
            return this.openIndex === index;
        },
        expandContainer(index) {
            this.openIndex !== index
                ? this.openIndex = index
                : this.openIndex = null
        },
        toggleAll (name) {
            if (this.openAccordion !== name) {
                this.openAccordion = name;
            } else {
                this.openAccordion = null
                this.openIndex = null
            }

        },
        allAccordionOpen (name) {
            return this.openAccordion === name
        }
    }
})