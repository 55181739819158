import Vue from 'vue'
const SearchMixin = require('../mixins/Search')
const TypeaheadMixin = require('../mixins/Typeahead')

Vue.component('SiteSearch', {
    mixins: [
        SearchMixin({
            endpoint: '/api/sitecore/sitesearchservice/search'
        }),
        TypeaheadMixin()
    ],    
    data: () => ({
        keyword: '',
        activeFilter: 'all',        
        searchedTerm: ''
    }),
    mounted() {
        this.filters = [
            { key: 'keyword', value: this.keyword },
            { key: 'page', value: this.page },
            { key: 'section', value: this.activeFilter }
        ]
        this.populateFromQueryStringAndSearch()
    },
    computed: {
        currentCount() {
            return this.hasResults ? this.results.length : 0
        },
        totalResults() {
            return this.total
        },
        stateToCache() {
            return {
                filters: this.filters,                
                results: this.results,
                total: this.total
            }
        },
        resultCounter() {
            if (this.results) {
                switch (this.results.length) {
                    case 0:
                        return `Showing ${this.currentCount} results for "${this.searchedTerm}"`
                    case 1:
                        return `Showing ${this.currentCount} result for "${this.searchedTerm}"`
                    default:
                        return `Showing ${this.currentCount} of ${this.totalResults} results for "${this.searchedTerm}"`
                }
            }
        }
    },
    methods: {
        formatSearchTitle(title, category){
            if(category !==undefined &&  category!='[]' && category!=''){
                return title + ' | ' + category;
            }else{
                return title;
            }
        },
        formatSearchDescription(articleDate, shortDescription){
            if(articleDate!==undefined && articleDate!='[]' && articleDate !=''){
                return articleDate + ' - ' + shortDescription;
            }else{
                return shortDescription;
            }
        },
        clearKeyword() {
            this.keyword = ''
            this.resetTypeahead()
            this.setFilter('keyword', this.keyword)
        },
        loadMore() {
            document.activeElement.blur()
            this.page++
            this.setFilter('page', this.page)
                .then(this.search({ onSuccess: this.onSuccess, onError: this.onError }))
        },
        loadAll() {
            this.results = []
            this.resetTypeahead()
            this.page = 1
            this.setFilter('page', this.page)
                .then(this.setFilter('viewall', true))
                .then(this.search({ onSuccess: this.onSuccess, onError: this.onError }))
        },
        isActiveFilter(filter) {
            return this.activeFilter === filter
        },
        setActiveFilter(filter) {
            this.activeFilter = filter            
            this.resetTypeahead()
            this.setDefaults()
            this.setFilter('section', filter)                
                .then(this.setFilter('page', this.page))
                .then(this.setFilter('viewall', this.false))
                .then(this.search({ onSuccess: this.onSuccess, onError: this.onError })) 
        },
        keywordSearch() {
            //redirects to typeahead item if focused and presses enter
            //else do keyword search
            if (this.redirectToTypeaheadResult()) {
                return
            }
                        
            this.resetTypeahead()
            if (this.keyword.length > 1) {
                this.setDefaults()
                this.searchedTerm = this.keyword
                this.setFilter('keyword', this.keyword)                    
                    .then(this.setFilter('page', this.page))
                    .then(this.setFilter('viewall', this.false))
                    .then(this.search({ onSuccess: this.onSuccess, onError: this.onError }))                
            }
        },
        typeaheadSearch(event) {
            if (this.keyword.length >= this.typeaheadMin) {
                if (this.isValidKeyCode(event.keyCode)) {
                    this.inPageSearch(
                        { onSuccess: this.onTypeaheadSuccess, onError: this.onTypeaheadError },
                        `/api/sitecore/globalsearch/get?keyword=${encodeURIComponent(this.keyword)}&take=${this.typeaheadTake}`),
                        false
                }
            } else {
                this.resetTypeahead()
            }
        },
        onSuccess(response) {
            let responseData = response.data
            this.results = this.results.concat(responseData.results)            
            this.total = responseData.resultsCount
            return Promise.resolve()
        },
        onError(response) {
            this.results = []
            this.total = 0
            return Promise.resolve()
        },
        _setStateFromCache(pathname) {
            return ({ state }) => {
                if (window.location.pathname === pathname && state) {
                    this.filters = state.filters                    
                    this.results = state.results
                    this.total = state.total
                    this.keyword = this.getFilter('keyword')
                    this.page = this.getFilter('page')
                } else {
                    this.filters = []
                    this.results = []
                    this.total = 0
                    this.keyword = ''
                    this.page = 1
                }
            }
        },
        populateFromQueryStringAndSearch() {
            const urlParams = new URLSearchParams(window.location.search)
            const section = urlParams.get('section')
            const keyword = urlParams.get('keyword')

            if (section) {
                this.activeFilter = section
                this.setFilter('section', section)
            }
            if (keyword) {
                this.keyword = keyword
                this.keywordSearch()
            }
        }
    }
})