import Vue from 'vue'

Vue.component('LocationsFauxDropdown', {
    data: () => ({
        isOpen: false
    }),
    computed: {
        menuIsOpen () {
            return this.isOpen
        }
    },
    methods: {
        toggleMenu () {
            this.isOpen = !this.isOpen
        },
        closeMenu() {
            setTimeout(() => {
                this.isOpen = false
            }, 100)            
        },
        isActivePage (url) {
            return window.location.pathname === url
        }
    }
})