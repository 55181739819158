import Vue from 'vue'

Vue.component('EmailDisclaimer', {    
    data: () => ({

    }),    
    methods: {        
        accept() {
            let mailToHref = 'mailto:'
            let mailToParts = []

            if (this.$root.activeEmail) {
                mailToHref += `${this.$root.activeEmail}`
            }
            if (this.$root.activeSubject) {
                mailToParts.push(`subject=${encodeURIComponent(this.$root.activeSubject)}`)
            }
            if (this.$root.activeBody) {
                mailToParts.push(`body=${encodeURIComponent(this.$root.activeBody)}`)
            }

            window.location.href = `${mailToHref}?${mailToParts.join('&')}`

            this.$root.showEmailDisclaimer = false
        },
        decline() {
            this.$root.showEmailDisclaimer = false
        }
    }
})