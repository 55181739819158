import Vue from 'vue'
import Masonry from 'masonry-layout'
const SearchMixin = require('../mixins/Search')

Vue.component('ExperienceSearch', {
    props: ['initialData'],
    mixins: [
        SearchMixin({
            endpoint: '/api/sitecore/experiencesearch/get'
        })
    ],   
    data: () => ({        
        selects: []        
    }),    
    mounted() {
        let initialData = JSON.parse(this.initialData)        
        this.results = initialData.results
        this.total = initialData.total
        this.filters = initialData.filters        
        this.selects = initialData.selects

        if (this.hasKeyword) {
            this.keyword = this.getFilter('keyword')            
        }
        this._replaceUrl() 
        if (this.hasAnyFilters) {
            setTimeout(() => {
                this.scrollTo('results', 0)
            }, 1000)
        }
        let experienceLandingGrid = document.getElementById('experienceLandingGrid')
        let msnry = new Masonry(experienceLandingGrid, {
            itemSelector: '.grid__item',
            columnWidth: 334,
            gutter: 20
        })
        console.log('masonry loaded')
    },
    computed: {

    },
    methods: {
        reloadMasonry () {
            setTimeout(() => {
                let experienceLandingGrid = document.getElementById('experienceLandingGrid')
                let msnry = new Masonry(experienceLandingGrid, {
                    itemSelector: '.grid__item',
                    columnWidth: 334,
                    gutter: 20
                })
                msnry.reloadItems()
            }, 100)
        },
        loadMore() {
            document.activeElement.blur()
            this.page++
            this.setFilter('page', this.page)
                .then(this.search({ onSuccess: this.onSuccess }))
            this.reloadMasonry()
        },
        loadAll() {
            this.setDefaults()
            setTimeout(() => {
                let experienceLandingGrid = document.getElementById('experienceLandingGrid')
                let msnry = new Masonry(experienceLandingGrid, {
                    itemSelector: '.grid__item',
                    columnWidth: 334,
                    gutter: 20
                })
                msnry.reloadItems()
            }, 200)
            this.setFilter('viewall', true)
                .then(this.setFilter('page', this.page))
                .then(this.search({ onSuccess: this.onSuccess }))
        },
        clearKeyword() {
            this.setDefaults()
            this.keyword = ''
            this.setFilter('keyword', undefined)
                .then(this.setFilter('viewall', false))
                .then(this.search({ onSuccess: this.onSuccess }))
            this.reloadMasonry()            
        },
        keywordSearch() {
            if (this.keyword.length > 1) {
                this.setDefaults()
                this.setFilter('keyword', this.keyword)
                    .then(this.setFilter('page', this.page))
                    .then(this.setFilter('viewall', false))
                    .then(this.search({ onSuccess: this.onSuccess }))
                this.reloadMasonry()
            }
        },
        selectSearch(key, value) {
            this.setDefaults()
            this.setFilter(key, value)
                .then(this.setFilter('page', this.page))
                .then(this.setFilter('viewall', false))
                .then(this.search({ onSuccess: this.onSuccess }))
            this.reloadMasonry()
        },
        isActiveFilter (key) {
            return this.getFilter(key)
        },
        clearFilterAndSearch(key) {
            this.setDefaults()
            this.setFilter(key, undefined)
                .then(this.setFilter('page', this.page))
                .then(this.setFilter('viewall', false))
                .then(this.search({ onSuccess: this.onSuccess }))
            this.reloadMasonry()            
        },
        clearFiltersAndSearch() {
            this.clearAllFilters()
            this.reloadMasonry()
            this.search({ onSuccess: this.onSuccess })
        },
        onSuccess(response) {
            let responseData = JSON.parse(response.data)            
            this.results = this.results.concat(responseData.results)
            this.total = responseData.total
            this.response = responseData
            return Promise.resolve()
        },
        onSearchError(response) {            
            console.log(response)            
            this.results = []
            this.total = 0
            return Promise.resolve()
        }        
    }
})