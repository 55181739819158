import Vue from 'vue'

Vue.component('HomeHero', {
  data: () => ({
    load: false
  }),
  mounted () {
    setTimeout(() => {
      this.loadElements()
      this.load = true
    }, 10)
  },
  computed: {
    hasLoaded () {
      return this.load
    }
  },
  methods: {
    loadElements () {
      let title = document.getElementById('title')
      let services = document.getElementById('services')
      if (document.getElementById('btn') !== null) {
        let btn = document.getElementById('btn')
      }
      if (document.getElementById('description') !== null) {
        let description = document.getElementById('description')
      }

      setTimeout(() => {
        title.classList.add('home-hero__title--load')
      }, 100)
      if (description) {
        setTimeout(() => {
          description.classList.add('home-hero__description--load')
        }, 300)
      }
      setTimeout(() => {
        services.classList.add('home-hero__services--load')
      }, 500)
      if (btn) {
        setTimeout(() => {
          btn.classList.add('home-hero__service-btn-wrapper--load')
        }, 700)
      }
    }
  }
})