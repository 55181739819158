import Vue from 'vue'

Vue.component('ServicesSort', {
  data: () => ({
    sortOption: 'category',
    openIndex: null,
    openCategory: null
  }),
  methods: {
    isActiveSort (sortOption) {
      return this.sortOption === sortOption
    },
    sortBy (sortOption) {
      this.sortOption = sortOption
      this.$root.$emit('change-sort', sortOption)
    },
    isContainerExpanded (index) {
      return this.openIndex === index
    },
    expandContainer (index) {
      this.openIndex !== index
        ? this.openIndex = index
        : this.openIndex = null
    },
    toggleAll (name) {
        if (this.openCategory !== name) {
            this.openCategory = name
        }
        else {
            this.openCategory = null
            this.openIndex = null
        }        
    },
    allCategoryOpen (name) {
      return this.openCategory === name
    }
  }
})