import Vue from 'vue'

Vue.component('StalkerGradient', {
  mounted () {
    this.stalkMouse()
  },
  methods: {
    stalkMouse () {
      window.addEventListener('mousemove', function(e) {
        let xPos = e.pageX
        let width = window.innerWidth

        let colors = [
          ['#032F84', '#4C3D55'],
          ['#B36155', '#C55243']
        ]

        function hexToRGB (hex) {
          let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
          return 'rgb(' + parseInt(result[1], 16) + ',' + parseInt(result[2], 16) + ',' + parseInt(result[3], 16) + ')'
        }

        function rgbToHex (rgb) {
          rgb = rgb.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/)
    
          function hex(x) {
            return ("0" + parseInt(x).toString(16)).slice(-2)
          }
          return "#" + hex(rgb[1]) + hex(rgb[2]) + hex(rgb[3])
        }

        function rgbArray (rgb) {
          return rgb.split('(')[1].split(')')[0].split(',')
        }

        function transitionColor (from, to, width, x) {
          let m = x / width
          let r, g, b
    
          r = Math.ceil(from[0] * m + to[0] * (1 - m));
          g = Math.ceil(from[1] * m + to[1] * (1 - m));
          b = Math.ceil(from[2] * m + to[2] * (1 - m));
    
          return rgbToHex('rgb(' + r + ', ' + g + ', ' + b + ')')
        }

        let topLeft = hexToRGB(colors[0][0])
        let topRight = hexToRGB(colors[1][0])
        let bottomLeft = hexToRGB(colors[0][1])
        let bottomRight = hexToRGB(colors[1][1])

        let bottomTransition = transitionColor(rgbArray(bottomRight), rgbArray(bottomLeft), width, xPos)
        let topTransition = transitionColor(rgbArray(topRight), rgbArray(topLeft), width, xPos)
        console.log('stalking...')

        document.getElementById('stalker').style.background = 'linear-gradient(' + topTransition + ', ' + bottomTransition + ')'
      })
    }
  }
})