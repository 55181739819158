import Vue from 'vue'
import Swiper from 'swiper'

Vue.component('PeopleCarousel', {
    data: () => ({
        activeSlide: 0
    }),
    mounted() {
        this.createSwiper()
    },
    methods: {
        createSwiper() {
            this.mySwiper = new Swiper('.swiper-container.people-carousel__container', {
                slidesPerView: 1,
                speed: 400
            })
            this.mySwiper.on('slideChange', (event) => {
                this.activeSlide = event.activeIndex
            })
        },
        nextSlide() {
            this.mySwiper.slideNext()
        },
        prevSlide() {
            this.mySwiper.slidePrev()
        },
        isActiveSlide(index) {
            return this.activeSlide === index
        },
        changeSlide(index) {
            this.mySwiper.slideTo(index)
            this.activeSlide = index
        }
    }
})