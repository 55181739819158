import Vue from 'vue'

Vue.component('StickyEmail', {
    data: () => ({
        showMail: false
    }),
    mounted() {
        this.stickyScroll()
    },
    methods: {
        stickyScroll() {
            if (window.innerWidth > 1024) {
                window.addEventListener('scroll', () => {
                    const overview = document.getElementById('overview')
                    const windowHeight = window.innerHeight
                    let footerPosition = document.getElementsByTagName('footer')[0].getBoundingClientRect().bottom

                    if (overview) {
                        if (overview.getBoundingClientRect().top < 0 && (footerPosition - windowHeight) > 150) {
                            this.showMail = true
                        } else if (overview.getBoundingClientRect().top < 0 && (footerPosition - windowHeight) < 150) {
                            this.showMail = false
                        } else if (overview.getBoundingClientRect().top > 0) {
                            this.showMail = false
                        }
                    }                    
                })
            }
        }
    }
})